// $primary: #a51616;
$primary: #dd1470; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
/*


nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 45px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}




@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

*/

.flash {
	display:none;
}
.padding {
	padding-left: 1em;
	padding-right: 1em;
}

.modal-dialog {
	max-width: 280px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		display: block;
		width: 100%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

input#username, input#password {
	width: 100%;
}

h1,h2,h3,h4 {
	color: $primary;
	text-align: center;
}

body {
	font-size: 2rem;
	font-weight: 100;
}
strong {
	color: $primary;
	text-transform: uppercase;
	font-weight: bold;
}


.color,
.social-profile li a:hover,
.contact-form input:hover, 
.contact-form textarea:hover,
#contact-submit:hover,
.post-meta a:hover,
.tab-post-nav li.active a,
.categories > li:hover a,
.next-prev a:hover,
.author-comment cite a:hover, 
.replay:hover,
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li.current > a  {
    color: $primary;
}


.social-icon > ul > li:hover a,
.post-excerpt .more a:hover,
.widget-content .tag:hover,
.post-pagination ul li a:hover, 
.post-pagination ul li.active a   {
    background-color: $primary;
}

.blog-icon {
    border: 1px solid $primary;
    color: $primary;
}

#comment-form .form-control:hover {
    border-color: $primary;
}


.logo {
	max-width: 225px;
	padding: 0.5em 0em;

	@media (max-width: 767px) {
		max-width: 175px;
		margin: 0.5em;
		margin-right: 0em;
	}

	@media (max-width: 400px) {
		max-width: 150px;

	}
}

.navbar-toggle {
	margin-top: 20px;
}

.btn-green {
    background-color: $primary;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 3rem;
}

.btn-green:focus, 
.btn-green:hover {
    background-color: #2F383D;
	color: #fff;
}

.btn-transparent {
	border: 1px solid #4e595f;
}

.btn-transparent:focus {
    background-color: transparent;
    border-color: $primary;
}

.btn-transparent:hover {
    background-color: $primary;
	border: 1px solid transparent;
    color: #fff;
}



footer {
	padding: 50px 0px 20px;
	color: $primary;

	a,p {
		color: $primary;

		&:hover {
			color: lighten($primary, 10%);
		}
	}

	@media (max-width: 767px) {
		padding: 50px 25px 0px;
	}
}

.carousel-caption img {
	margin: 0 auto;
}

@media (max-width: 767px) {
	.navbar-right {
		margin-top: 0px;
	}
  .navbar .navbar-nav > li > a {
    height: 40px;
    display: block;
	text-align: center;
  }

  .navbar-inverse .navbar-nav > li > a {
  	line-height: 0px;
  	padding: 20px 0px;
  }


	#home {
		.bg-img-1 {
			background-position: 60% 0%;
		}

		.bg-img-2 {
			background-position: left;		
		}
	}

	.carousel-caption img {
		max-width: 70%;
		margin: 0 auto;
	}

}


hr {
	width: 80%;
}